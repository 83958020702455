<template>
  <div id="app">
    <div class="background-image">
      <img src="/bg.png" alt="Background Image" />
    </div>
 
    <div class="content">
      <p class="text">HERE IS, MY FINISHED TEXTILE PORTRAIT OF $Debbie Harry SEWN ENTIRELY 
        FROM DIFFERENT FABRICS STITCHED TO A RED COTTON BACKGROUND. SIZE 12” SQUARE & BOX 
        FRAMED UNDER GLASS. I REALLY LOVED SEWING THIS BEAUTIFUL WOMAN.
      </p>
      <div class="additional-text" @click="copyText">
  <span>CA:</span><span>DJw1ejYobYLV1E9TPxhenuimp2oN4XSa1xwTnwnypump</span>
</div>
    </div>
    
    <div class="social-icons">
      <a href="https://x.com/SolDebbie14419" target="_blank">
        <img src="/icon_tw@3x.png" alt="Twitter" />
      </a>

      <a href="https://t.me/Debbie_sol" target="_blank">
        <img src="/icon_telg@3x.png" alt="Twitter" />
      </a>

  
    </div>


  
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    copyText() {
      const textToCopy = 'DJw1ejYobYLV1E9TPxhenuimp2oN4XSa1xwTnwnypump'; // 获取需要复制的文本
      navigator.clipboard.writeText(textToCopy) // 使用 Clipboard API 复制文本
        .then(() => {
          console.log('Text copied successfully!');
        })
        .catch(err => {
          console.error('Error copying text: ', err);
        });
    }
  }
}
</script>

<style>
/* 导入字体 */
@font-face {
  font-family: 'hergika-6y9jy';
  src: url('@/assets/fonts/hergika-6y9jy.ttf') format('truetype');
}

/* 全局设置字体和基本样式 */
html, body {
  margin: 0;
  height: 100%;
  font-family: 'hergika-6y9jy';
}

#app {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持图像铺满整个容器 */
}

.content {
  z-index: 2;
  position: absolute;
  top:27%; /* 向下移动 20% */
  left: 27.5%;
  transform: translate(-50%, 0);
  color: black;
}

.text {
  font-size: 1.3em;
  max-width: 650px;
  overflow-wrap: break-word;
  margin-top: 18px;
  margin-bottom: 30px;
  line-height: 1.8;
  letter-spacing: 0.02em
}

.additional-text {
  /*  left: 5%;  向左移动 5% */
  margin-top: 7px;
  font-size: 1.4em;
  color: black;
  cursor: pointer; /* 添加指针样式 */
  letter-spacing: 0.07em
  
  
}



.additional-text span {
  text-decoration: none; /* 去除默认下划线 */
}

.additional-text:hover span:last-child {
  text-decoration: underline; /* 鼠标悬停时对最后一个 span 元素添加下划线 */
}

.additional-text {
  margin-top: -9px; /* 向上移动 additional-text */
}


.social-icons {
  position: absolute;
  top:54%; /* 向下移动 20% */
  left: 13.5%;
  transform: translate(-50%, 0);
  display: flex;
  z-index: 1;
}

.social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  background-color:  #000000;;
  border-radius: 50%;
  margin-left: 50px;
  transition: background-color 0.3s, transform 0.3s;
}



.social-icons img {
  width: 22px;
  height: 22px;
}

.social-icons a:hover {
  background-color: #890D01;
  transform: scale(1.1);
}





</style>
